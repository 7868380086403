import React, { useState } from "react";
const adminService = require("../../services/AdminService");

// DefaultActions Component
function DefaultActions({
    selectedAction,
    setSelectedAction,
    fetchDatabaseData,
    selectedDatabase,
}) {
    const openDatabaseEntryForm = async () => {
        setSelectedAction("Add Entry");
        try {
            const response = await adminService.addDatabaseEntry(
                selectedDatabase
            );
            console.log("Response:", response.data);
            fetchDatabaseData();
        } catch (error) {
            console.error(
                "Error adding database entry:",
                error.response ? error.response.data : error.message
            );
        }
    };

    return (
        <>
            <li
                className={selectedAction === "Add Entry" ? "selected" : ""}
                onClick={openDatabaseEntryForm}
            >
                Add Entry
            </li>
        </>
    );
}

// UserActions Component
function UserActions({ selectedAction, setSelectedAction }) {
    return (
        <>
            <li
                className={selectedAction === "Add User" ? "selected" : ""}
                onClick={() => setSelectedAction("Add User")}
            >
                Add User
            </li>
        </>
    );
}

// AccessCodeActions Component
function AccessCodeActions({
    selectedAction,
    setSelectedAction,
    fetchDatabaseData,
}) {
    // Destructure fetchDatabaseData from props

    const handleGenerateAccessCode = async () => {
        setSelectedAction("Generate Access Code");
        await adminService.addAccessCode();
        fetchDatabaseData();
        // Handle the response if needed
    };
    return (
        <>
            <li
                className={
                    selectedAction === "Generate Access Code" ? "selected" : ""
                }
                onClick={handleGenerateAccessCode}
            >
                Generate Access Code
            </li>
            <li
                className={
                    selectedAction === "Disable Access Code" ? "selected" : ""
                }
                onClick={() => setSelectedAction("Disable Access Code")}
            >
                Disable Access Code
            </li>
        </>
    );
}

export function DatabaseActions({ selectedDatabase, fetchDatabaseData }) {
    const [selectedAction, setSelectedAction] = useState(null);

    return (
        <>
            <ul>
                {selectedDatabase && (
                    <DefaultActions
                        fetchDatabaseData={fetchDatabaseData}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        selectedDatabase={selectedDatabase}
                    />
                )}
                {selectedDatabase === "User" && (
                    <UserActions
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                    />
                )}
                {selectedDatabase === "AccessCode" && (
                    <AccessCodeActions
                        fetchDatabaseData={fetchDatabaseData}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                    />
                )}
            </ul>
        </>
    );
}
