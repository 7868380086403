// AutoResizedTextField.js
import React, { useRef, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

const AutoResizedTextField = ({
    variant = "filled",
    minRows = 1,
    maxRows = 4,
    value,
    ...props
}) => {
    const fieldRef = useRef(null);

    const resize = useCallback(() => {
        const inputField = fieldRef.current;
        if (!inputField) return;

        const shadowRoot = inputField.shadowRoot;
        if (!shadowRoot) return;

        const inputElement = shadowRoot.querySelector(".input");
        if (!inputElement) return;

        const computedStyle = window.getComputedStyle(inputElement);
        const lineHeightStr = computedStyle.lineHeight;
        const LINE_HEIGHT = parseInt(lineHeightStr, 10) || 24;

        // Calculate desired height
        inputElement.style.overflow = "hidden";
        inputElement.style.height = "auto";

        const scrollHeight = inputElement.scrollHeight;
        const minHeight = LINE_HEIGHT * minRows;
        const maxHeight = LINE_HEIGHT * maxRows;

        const clampedHeight = Math.min(Math.max(scrollHeight, minHeight), maxHeight);

        // Only update height if it's different to prevent triggering observer again
        if (inputElement.style.height !== `${clampedHeight}px`) {
            requestAnimationFrame(() => {
                inputElement.style.height = `${clampedHeight}px`;
            });
        }

        inputElement.style.overflow = "";
    }, [minRows, maxRows]);

    // Memoize the debounced resize function
    const debouncedResize = useMemo(() => debounce(resize, 100), [resize]);

    // Clean up the debounced function on unmount
    useEffect(() => {
        return () => {
            debouncedResize.cancel();
        };
    }, [debouncedResize]);

    // Example usage: attaching the debounced function to a window resize event
    useEffect(() => {
        window.addEventListener("resize", debouncedResize);
        return () => {
            window.removeEventListener("resize", debouncedResize);
        };
    }, [debouncedResize]);

    useEffect(() => {
        const inputField = fieldRef.current;
        if (!inputField) return;

        const shadowRoot = inputField.shadowRoot;
        if (!shadowRoot) return;

        const inputElement = shadowRoot.querySelector(".input");
        if (!inputElement) return;

        // Perform initial resize on mount
        resize();

        // Attach the resize function to the input event
        inputElement.addEventListener("input", resize);

        // Setup ResizeObserver with debounced callback
        const resizeObserver = new ResizeObserver(debouncedResize);
        resizeObserver.observe(inputElement);

        // Cleanup event listener and observer on unmount
        return () => {
            inputElement.removeEventListener("input", resize);
            resizeObserver.disconnect();
            debouncedResize.cancel(); // Cancel any pending debounced calls
        };
    }, [resize, debouncedResize]);

    useEffect(() => {
        resize();
    }, [value, resize]);

    useEffect(() => {
        if (value === "") {
            fieldRef.current.reset();
            resize();
        }
    }, [resize, value]);

    const TagName = variant === "outlined" ? "md-outlined-text-field" : "md-filled-text-field";

    return <TagName ref={fieldRef} value={value} {...props} />;
};

AutoResizedTextField.propTypes = {
    variant: PropTypes.oneOf(["filled", "outlined"]),
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
    value: PropTypes.string.isRequired,
    onInput: PropTypes.func,
};

export default AutoResizedTextField;
