import React, { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import ConversationArea from "./components/ConversationArea";
import { useWebSocket } from "./components/WebSocketContext"; // Import the useWebSocket hook
import WebSocketService from "./components/WebSocketService";

import SidePanel from "./components/SidePanel";
import AutoResizedTextField from "./components/mdc/AutoResizedTextField";
axios.defaults.withCredentials = true;

function App() {
    const navigate = useNavigate();
    const [preferences, setPreferences] = useState({
        language: null,
        showTransliteration: null,
        theme: null,
        masteryThreshold: null,
    });
    const [userType, setUserType] = useState();
    const [conversationDetails, setConversationDetails] = useState({
        conversationId: "",
        conversationHistory: [],
        persona: "",
    });
    const [initialConversationDetails, setInitialConversationDetails] = useState({
        conversationId: "",
        conversationHistory: [],
        persona: "",
    });
    const [scenarioState, setScenarioState] = useState({
        reasonForLearning: "",
        additionalNotes: "",
        languageLearnerRole: "",
        conversationPartnerRole: "",
        informationToGive: "",
        informationToRequest: "",
        context: "",
    });

    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [messageInput, setMessageInput] = useState(
        localStorage.getItem("myInputValue") || ""
    );

    const menuButtonRef = useRef(null);

    const { connectionStatus, sendMessage, retryConnection } = useWebSocket();

    const togglePanel = useCallback(() => {
        setIsPanelOpen((prevIsOpen) => !prevIsOpen);
    }, []);

    useEffect(() => {
        if (preferences.theme) {
            import(`./themes/${preferences.theme}.css`)
                .then(() => {})
                .catch((err) => {
                    console.error(`Failed to load ${preferences.theme} theme`, err);
                });
            document.body.className = preferences.theme;
        }
    }, [preferences.theme]); // Re-run effect when theme changes

    useEffect(() => {
        const fetchConversationHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/request-conversation-history`
                );
                const { conversationDetails, scenario, preferences } = response.data;
                setInitialConversationDetails(conversationDetails);
                setConversationDetails(conversationDetails);
                setScenarioState(scenario);
                setPreferences(preferences);
            } catch (error) {
                console.error("Error fetching conversation history:", error);
            }
        };
        fetchConversationHistory();
    }, []);

    useEffect(() => {
        // Trigger the backend to check the HttpOnly cookie
        axios
            .get(`${process.env.REACT_APP_API_URL}/validate-token`, {
                withCredentials: true,
            }) // ensure to send withCredentials if cookies are used
            .then((response) => {
                if (response.data.valid) {
                    setUserType(response.data.userType);
                } else {
                    console.log("Token validation failed:", response.data.message);
                    setUserType(null);
                }
            })
            .catch((error) => {
                console.error("Error validating token:", error);
                navigate("/login");
                setUserType(null);
            });
        retryConnection();
    }, [navigate, retryConnection]);

    useEffect(() => {
        // Define a handler for generic messages
        const handleMessage = (data) => {
            if (data.conversationDetails) {
                const { scenario, ...processedConversationDetails } = data.conversationDetails;
                setConversationDetails(processedConversationDetails);
                setInitialConversationDetails(processedConversationDetails);
            }
            if (data.scenario) {
                setScenarioState(data.scenario);
            }
            if (data.preferences) {
                setPreferences(data.preferences);
            }
            if (data.userType) {
                setUserType(data.userType);
            }
        };

        // Subscribe to the generic "message" event
        WebSocketService.on("message", handleMessage);

        // Clean up the subscription on unmount
        return () => {
            WebSocketService.off("message", handleMessage);
        };
    }, []);

    const handleResetConversation = async () => {
        setMessageInput("");
        localStorage.removeItem("myInputValue");
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/reset-conversation`);
        } catch (error) {
            console.error("Error resetting conversation:", error);
        }
    };

    return (
        <div className={preferences.theme}>
            <div className="App">
                <div className="app-container">
                    <header className="app-header">
                        <span className="navigation-menu-button">
                            <md-icon-button ref={menuButtonRef} onClick={togglePanel}>
                                <md-icon>menu</md-icon>
                            </md-icon-button>
                        </span>
                    </header>
                    {scenarioState.languageLearnerRole !== "" && (
                        <ScenarioGoals scenarioState={scenarioState} />
                    )}
                    <SidePanel
                        {...{
                            isPanelOpen,
                            togglePanel,
                            conversationDetails,
                            setConversationDetails,
                            setInitialConversationDetails,
                            scenarioState,
                            setScenarioState,
                            handleResetConversation,
                            userType,
                            preferences,
                            setPreferences,
                            menuButtonRef,
                        }}
                    />

                    <ConversationArea
                        {...{
                            preferences,
                            setPreferences,
                            conversationDetails,
                            setConversationDetails,
                            initialConversationDetails,
                            setInitialConversationDetails,
                        }}
                    />
                    <MessageInput
                        {...{
                            messageInput,
                            setMessageInput,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const MessageInput = ({ messageInput, setMessageInput }) => {
    const [isSending, setIsSending] = useState();

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setMessageInput(inputValue);
        localStorage.setItem("myInputValue", inputValue);
    };

    // Submit handler for the message form
    const handleSubmit = async (e) => {
        e.preventDefault();
        const message = messageInput.trim();
        if (!message) return;
        if (isSending) return;
        setIsSending(true);

        setMessageInput("");
        localStorage.removeItem("myInputValue");
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/send-message`,
                {
                    message,
                },
                {
                    withCredentials: true,
                }
            );
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <form className="message-form" onSubmit={handleSubmit}>
            <AutoResizedTextField
                variant="outlined"
                id="userInput"
                type="textarea"
                value={messageInput}
                rows="1"
                placeholder="Type your mixed language message..."
                onInput={(e) => {
                    handleInputChange(e);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                        handleSubmit(e);
                        e.preventDefault();
                    }
                }}
            />
            <md-icon-button type="submit" className="send-button" softDisabled={isSending}>
                <md-icon filled>send</md-icon>
            </md-icon-button>
        </form>
    );
};
const ScenarioGoals = ({ scenarioState }) => {
    const dialogRefScenarioGoals = useRef(null);

    const openScenarioGoalsDialog = () => {
        dialogRefScenarioGoals.current.open = true;
    };
    const closeDialog = (e) => {
        dialogRefScenarioGoals.current.open = false;
    };
    return (
        <>
            <span className="scenario-goals-button">
                <md-icon-button onClick={openScenarioGoalsDialog}>
                    <md-icon>menu_open</md-icon>
                </md-icon-button>
            </span>
            <div>
                <md-dialog ref={dialogRefScenarioGoals}>
                    <span slot="headline" className="headline">
                        Scenario Goals
                        <md-icon-button
                            class="dialog-close-button"
                            value="close"
                            aria-label="Close Scenario Goals"
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            <md-icon>close</md-icon>
                        </md-icon-button>
                    </span>
                    <form id="confirmationForm" slot="content" method="dialog">
                        <div className="scenario-manager">
                            <AutoResizedTextField
                                value={scenarioState.languageLearnerRole}
                                label="Language Learner Role"
                                placeholder="e.g., Student, Tourist"
                                readOnly
                            />
                        </div>
                        <div className="scenario-manager">
                            <AutoResizedTextField
                                value={scenarioState.conversationPartnerRole}
                                label="Conversation Partner Role"
                                placeholder="e.g., Teacher, Local Resident"
                                readOnly
                            />
                        </div>
                        <div className="scenario-manager">
                            <AutoResizedTextField
                                type="textarea"
                                value={scenarioState.informationToGive}
                                label="Information to Give"
                                placeholder="e.g., Personal details, Preferences"
                            />
                        </div>
                        <div className="scenario-manager">
                            <AutoResizedTextField
                                type="textarea"
                                value={scenarioState.informationToRequest}
                                label="Information to Request"
                                placeholder="e.g., Directions, Recommendations"
                                readOnly
                            />
                        </div>
                        <div className="scenario-manager">
                            <AutoResizedTextField
                                type="textarea"
                                value={scenarioState.context}
                                label="Context"
                                placeholder="e.g., Formal meeting, Casual conversation"
                                readOnly
                            />
                        </div>
                    </form>
                </md-dialog>
            </div>
        </>
    );
};

export default App;
