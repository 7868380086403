import React, { useState } from "react";
const adminService = require("../../services/AdminService");

// DefaultActions Component
function DefaultActions({
    selectedAction,
    setSelectedAction,
    fetchDatabaseData,
    selectedDatabase,
}) {
    return;
}

function UserActions({
    selectedAction,
    setSelectedAction,
    selectedRecord,
    handleSetResetPassword,
}) {
    const [message, setMessage] = useState("");

    return (
        <>
            <li
                className={
                    selectedAction === "Deactivate User" ? "selected" : ""
                }
                onClick={() => setSelectedAction("Deactivate User")}
            >
                Deactivate User
            </li>
            <li
                className={
                    selectedAction === "Reset Password" ? "selected" : ""
                }
                onClick={() => {
                    setSelectedAction("Reset Password");
                    handleSetResetPassword(selectedRecord);
                }}
            >
                Reset Password
            </li>
            {message && <p>{message}</p>}
        </>
    );
}

/* // AccessCodeActions Component
function AccessCodeActions({
  selectedAction,
  setSelectedAction,
  fetchDatabaseData,
}) {
  // Destructure fetchDatabaseData from props

  return;
} */

export function RecordActions({
    selectedDatabase,
    fetchDatabaseData,
    selectedRecord,
    handleSetResetPassword,
}) {
    const [selectedAction, setSelectedAction] = useState(null);
    //console.log("selectedRecord", selectedRecord);
    return (
        <>
            <ul>
                {selectedDatabase && (
                    <DefaultActions
                        fetchDatabaseData={fetchDatabaseData}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        selectedDatabase={selectedDatabase}
                    />
                )}
                {selectedDatabase === "User" && (
                    <UserActions
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        selectedRecord={selectedRecord}
                        handleSetResetPassword={handleSetResetPassword}
                    />
                )}
                {/* selectedDatabase === "AccessCode" && (
          <AccessCodeActions
            fetchDatabaseData={fetchDatabaseData}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction} />
        ) */}
            </ul>
        </>
    );
}
