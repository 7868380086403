import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useWebSocket } from "./WebSocketContext";
import WebSocketService from "./WebSocketService";

function ConversationArea({
    preferences,
    setPreferences,
    conversationDetails,
    setConversationDetails,
    initialConversationDetails,
    setInitialConversationDetails,
}) {
    const [activeMessageId, setActiveMessageId] = useState(null);
    const [wordBank, setWordBank] = useState([]);
    const [localWordBank, setLocalWordBank] = useState([]);
    const [newMessageIds, setNewMessageIds] = useState([]);
    const [expandedMessageId, setExpandedMessageId] = useState(null);
    const [formattedConversationHistory, setFormattedConversationHistory] = useState([]);
    const [selectedMorphemes, setSelectedMorphemes] = useState([]);

    const { connectionStatus, sendMessage, retryConnection } = useWebSocket();
    useEffect(() => {
        if (!conversationDetails || !initialConversationDetails) return;
        const initialIds = new Set(
            initialConversationDetails.conversationHistory.map((msg) => msg.id)
        );
        const newIds = conversationDetails.conversationHistory
            .map((msg) => msg.id)
            .filter((id) => !initialIds.has(id));
        setNewMessageIds(newIds);
    }, [conversationDetails]);

    useEffect(() => {
        // Process the conversation history and update localWordBank
        const processConversation = () => {
            // Create a shallow copy of wordBank to avoid direct mutations
            const updatedWordBank = wordBank.map((word) => ({ ...word }));
            initialConversationDetails.conversationHistory.forEach((message) => {
                const processedMorphemeDetails = processMorphemeDetails(
                    message.morphemeTranslationDetails
                );
                processedMorphemeDetails.forEach((morpheme) => {
                    decrementEffectiveCount(
                        updatedWordBank,
                        morpheme.originalMorpheme.toLowerCase()
                    );
                });
            });
            setLocalWordBank(updatedWordBank);
        };

        // Helper function to decrement effectiveCount for a given morpheme
        const decrementEffectiveCount = (bank, originalMorpheme) => {
            const word = bank.find((word) => word.originalMorpheme === originalMorpheme);
            if (!word || word.effectiveCount <= 0) return;
            word.effectiveCount -= 1;
        };

        processConversation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordBank, initialConversationDetails]);

    // Helper function to parse morphemeTranslationDetails
    const processMorphemeDetails = (morphemeDetails = {}) =>
        Object.values(morphemeDetails)
            .map(([originalMorpheme, transliteratedMorpheme, translatedMorpheme]) => ({
                originalMorpheme,
                transliteratedMorpheme,
                translatedMorpheme,
            }))
            .filter(
                ({ originalMorpheme, transliteratedMorpheme }) =>
                    originalMorpheme && transliteratedMorpheme
            );

    useEffect(() => {
        const getSeenCount = (word, morphemeList) => {
            const normalizedWord = word.toLowerCase().trim();
            // Find the morpheme in the localWordBank
            const morpheme = localWordBank.find(
                (morpheme) => morpheme.originalMorpheme === normalizedWord
            );
            // Get the effectiveCount from localWordBank
            const localCount = morpheme ? morpheme.effectiveCount : 0;

            // Get the count from morphemeList
            const listCount = morphemeList[normalizedWord] || 0;

            // Return the combined count
            return localCount + listCount;
        };

        const calculateOpacity = (seenCount) => {
            const opacity = 1 - Math.min(seenCount / preferences.masteryThreshold, 1);
            return opacity;
        };
        // Function to format the conversation history
        const formatConversationHistory = () => {
            if (
                !conversationDetails ||
                !Array.isArray(conversationDetails.conversationHistory)
            ) {
                console.error("Invalid conversation details provided.");
                return;
            }
            const morphemeList = {};
            const selectedMorphemesSet = new Set(selectedMorphemes);

            // Helper function to increment the morpheme count
            const incrementMorphemeCount = (morpheme, morphemeList) => {
                if (morphemeList.hasOwnProperty(morpheme)) {
                    morphemeList[morpheme] += 1;
                } else {
                    morphemeList[morpheme] = 1;
                }
            };

            // Helper function to check if a string is solely punctuation
            const isPunctuation = (str) => {
                // The regex matches strings that consist entirely of Unicode punctuation characters
                return /^[\p{P}]+$/u.test(str);
            };

            // Helper function to create a morpheme object
            const createMorphemeObject = (
                morpheme,
                index,
                morphemeList,
                selectedMorphemesSet
            ) => {
                let { originalMorpheme, transliteratedMorpheme, translatedMorpheme } =
                    morpheme;

                // Check if originalMorpheme exists and is a string
                if (!originalMorpheme || typeof originalMorpheme !== "string") {
                    return null;
                }

                // If originalMorpheme is only punctuation, replace transliterated and translated morphemes with ""
                if (isPunctuation(originalMorpheme)) {
                    transliteratedMorpheme = "";
                    translatedMorpheme = "";
                } else if (
                    !transliteratedMorpheme ||
                    typeof transliteratedMorpheme !== "string"
                ) {
                    // If transliteratedMorpheme is missing or not a string, skip this morpheme
                    return null;
                }

                // Increment the count for the original morpheme
                incrementMorphemeCount(originalMorpheme, morphemeList);

                const key = `morpheme-${index}`;
                const seenCount = getSeenCount(originalMorpheme, morphemeList);
                const opacity = calculateOpacity(seenCount);
                const isSelected = selectedMorphemesSet.has(originalMorpheme);

                return {
                    key,
                    originalMorpheme,
                    translatedMorpheme,
                    transliteratedMorpheme,
                    opacity,
                    isSelected,
                };
            };

            // Helper function to process morphemes for a single message
            const processMorphemesForMessage = (
                message,
                morphemeList,
                selectedMorphemesSet
            ) => {
                const processedMorphemeDetails = processMorphemeDetails(
                    message.morphemeTranslationDetails
                );

                return processedMorphemeDetails
                    .filter((morpheme) => morpheme && typeof morpheme === "object")
                    .map((morpheme, index) =>
                        createMorphemeObject(
                            morpheme,
                            index,
                            morphemeList,
                            selectedMorphemesSet
                        )
                    )
                    .filter((morpheme) => morpheme !== null);
            };

            // Main refactored mapping function
            const formattedHistory = conversationDetails.conversationHistory.map((message) => {
                const morphemeArray = processMorphemesForMessage(
                    message,
                    morphemeList,
                    selectedMorphemesSet
                );

                return {
                    ...message,
                    morphemeArray,
                };
            });

            // Update the formatted conversation history state
            setFormattedConversationHistory(formattedHistory);
        };
        formatConversationHistory();
    }, [
        conversationDetails,
        preferences.masteryThreshold,
        selectedMorphemes,
        wordBank,
        localWordBank,
    ]); // Re-run when conversationDetails changes

    useEffect(() => {
        // Handler for messages that contain a "target"
        const handleTargetMessage = (data) => {
            addSnapshotToHistory(data);
        };

        // Subscribe to "message:target" event
        WebSocketService.on("message:target", handleTargetMessage);

        return () => {
            WebSocketService.off("message:target", handleTargetMessage);
        };
    }, []);
    // Initialize a ref to store recorded actions
    const addSnapshotToHistory = (snapshot) => {
        const messageId = snapshot.target;
        const field = snapshot.subTarget;
        const content = snapshot.content;
        if (messageId === "scenarioSnapshot") return;
        setConversationDetails((prevDetails) => {
            const messageExists = prevDetails.conversationHistory.some(
                (message) => message.id === messageId
            );
            if (messageExists) {
                // If the message exists, update the specific field by adding to its existing content
                return {
                    ...prevDetails,
                    conversationHistory: prevDetails.conversationHistory.map((message) =>
                        message.id === messageId
                            ? {
                                  ...message,
                                  [field]: content,
                              }
                            : message
                    ),
                };
            } else {
                // If the message does not exist, create a new message
                const newMessage = {
                    id: messageId,
                    [field]: content,
                };
                return {
                    ...prevDetails,
                    conversationHistory: [...prevDetails.conversationHistory, newMessage],
                };
            }
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const optionsContainer = document.querySelector(".message-options-container");
            if (!optionsContainer) return;
            if (!optionsContainer.contains(event.target)) {
                setActiveMessageId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        // Must be mousedown. Checking click causes it to close prematurely
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []); // Ensure dependencies are correctly set if any

    const fetchMorphemes = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/get-morphemes`,
                {
                    withCredentials: true,
                }
            );
            // Sort the data by count in descending order
            const sortedData = response.data.morphemes.sort(
                (a, b) => b.trueCount - a.trueCount
            );
            const masteryThreshold = response.data.masteryThreshold;
            setPreferences((prevPreferences) => ({
                ...prevPreferences, // Spread the previous state
                masteryThreshold, // Update only masteryThreshold
            }));
            setWordBank(sortedData);
            return sortedData;
        } catch (error) {
            console.error("Error fetching morphemes:", error);
            console.error("Error details:", error.response);
            throw error;
        }
    };
    useEffect(() => {
        fetchMorphemes();
    }, [initialConversationDetails]);

    const handleFullSentenceTranslation = async () => {
        if (!activeMessageId) return;
        setActiveMessageId(null); // Close the menu

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/translate-sentence`, {
                messageId: activeMessageId,
            });
        } catch (error) {
            console.error("Error translating sentence:", error);
        }
    };

    const handleWordOptions = (messageId) => {
        setActiveMessageId(null); // Close the menu
        if (expandedMessageId === messageId) {
            // Collapse if the same message is clicked again
            setExpandedMessageId(null);
        } else {
            setExpandedMessageId(messageId);
        }
    };
    const renderMessageOptions = () => {
        return (
            <div className={`message-options-container ${activeMessageId ? "visible" : ""}`}>
                <md-outlined-button
                    className="button"
                    onClick={handleFullSentenceTranslation}
                    title="Translate Message"
                    aria-label="Translate Message"
                >
                    <md-icon
                        style={{
                            transform: "translateY(11.5%)",
                            fontSize: "1rem",
                        }}
                    >
                        translate
                    </md-icon>
                    Full Sentence Translation
                </md-outlined-button>

                <md-outlined-button
                    className="button"
                    onClick={() => handleWordOptions(activeMessageId)}
                    title="Word Options"
                    aria-label="Word Options"
                >
                    <md-icon
                        style={{
                            transform: "translateY(11.5%)",
                            fontSize: "1rem",
                        }}
                    >
                        build_circle
                    </md-icon>
                    Word Options
                </md-outlined-button>
            </div>
        );
    };

    return (
        <div className="conversation-area">
            <div className="scroll-wrapper">
                <div className="message-list">
                    {formattedConversationHistory.map((message) => (
                        <MessageContainer
                            key={message.id}
                            isExpanded={expandedMessageId === message.id}
                            {...{
                                message,
                                preferences,
                                setActiveMessageId,
                                wordBank,
                                setWordBank,
                                newMessageIds,
                                setExpandedMessageId,
                                selectedMorphemes,
                                setSelectedMorphemes,
                                fetchMorphemes,
                            }}
                        />
                    ))}
                </div>
            </div>
            {activeMessageId !== null && renderMessageOptions()}
        </div>
    );
}
function MessageContainer({
    message,
    preferences,
    setActiveMessageId,
    wordBank,
    setWordBank,
    newMessageIds,
    isExpanded,
    setExpandedMessageId,
    selectedMorphemes,
    setSelectedMorphemes,
    fetchMorphemes,
}) {
    const [showMorphemeTranslationDetails, setShowMorphemeTranslationDetails] = useState(true);

    const toggleMorphemeTranslationDetails = () => {
        // Disable toggling if isExpanded is true
        if (isExpanded) return;
        setShowMorphemeTranslationDetails((prev) => !prev);
    };

    // Refs for managing press state
    const pressTimerRef = useRef(null);
    const isLongPressRef = useRef(false);
    const isClickEventRef = useRef(false);
    const isScrollingRef = useRef(false);
    const initialPointerPosRef = useRef({ x: 0, y: 0 });

    // Movement threshold in pixels (vertical only)
    const MOVE_THRESHOLD_VERTICAL_MOUSE = 10;
    const MOVE_THRESHOLD_VERTICAL_TOUCH = 20;

    // Ref to store current movement threshold
    const moveThresholdRef = useRef(MOVE_THRESHOLD_VERTICAL_MOUSE);

    // Cleanup timer on unmount
    useEffect(() => {
        return () => {
            clearTimeout(pressTimerRef.current);
        };
    }, []);

    // Start the long press timer and record initial position
    const handlePressStart = (event) => {
        // Only respond to primary button (usually left mouse button) or touch/pen
        if (event.pointerType === "mouse" && event.button !== 0) return;

        event.stopPropagation();

        // Reset scrolling flag on new press
        isScrollingRef.current = false;
        isClickEventRef.current = true;
        isLongPressRef.current = false;

        // Record initial pointer position
        initialPointerPosRef.current = { x: event.clientX, y: event.clientY };

        // Determine vertical movement threshold based on pointer type
        const moveThreshold =
            event.pointerType === "touch"
                ? MOVE_THRESHOLD_VERTICAL_TOUCH
                : MOVE_THRESHOLD_VERTICAL_MOUSE;

        // Store the threshold in a ref for access in handlePointerMove
        moveThresholdRef.current = moveThreshold;

        pressTimerRef.current = setTimeout(() => {
            if (!isScrollingRef.current) {
                isLongPressRef.current = true;
                setActiveMessageId(message.id); // Set active message on long press
            }
        }, 500); // Long press duration
    };

    // Handle pointer movement with vertical threshold
    const handlePointerMove = (event) => {
        const { y } = initialPointerPosRef.current;
        const deltaY = Math.abs(event.clientY - y);
        const currentMoveThreshold = moveThresholdRef.current;

        if (deltaY > currentMoveThreshold) {
            isScrollingRef.current = true;
            clearTimeout(pressTimerRef.current); // Cancel long press if moving beyond threshold
        }
    };

    // Handle pointer cancel (e.g., touch cancel)
    const handlePointerCancel = () => {
        // Reset all flags and clear timer
        isClickEventRef.current = false;
        isLongPressRef.current = false;
        isScrollingRef.current = false;
        clearTimeout(pressTimerRef.current);
    };

    // Handle press end
    const handlePressEnd = (event) => {
        // Only respond to primary button or touch/pen
        if (event.pointerType === "mouse" && event.button !== 0) return;

        event.stopPropagation();
        clearTimeout(pressTimerRef.current);

        if (!isLongPressRef.current && isClickEventRef.current && !isScrollingRef.current) {
            toggleMorphemeTranslationDetails();
        }

        // Reset refs
        isClickEventRef.current = false;
        isLongPressRef.current = false;
        isScrollingRef.current = false;
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    return (
        <div
            className={`message ${message.sender} ${isExpanded ? "message-expanded" : ""}`} // Apply the expanded class conditionally
            onPointerDown={handlePressStart}
            onPointerUp={handlePressEnd}
            onPointerMove={handlePointerMove}
            onPointerCancel={handlePointerCancel} // Handle pointer cancel events
            style={{ userSelect: "none" }} // Optional: Prevent text selection during long press
            onContextMenu={handleContextMenu}
        >
            <MessageComponent
                key={message.id}
                {...{
                    message,
                    preferences,
                    showMorphemeTranslationDetails,
                    wordBank,
                    setWordBank,
                    newMessageIds,
                    isExpanded,
                    selectedMorphemes,
                    setSelectedMorphemes,
                }}
            />
            {isExpanded && (
                <MorphemeMenu
                    selectedMorphemes={selectedMorphemes}
                    setExpandedMessageId={setExpandedMessageId}
                    fetchMorphemes={fetchMorphemes}
                />
            )}
        </div>
    );
}

function MessageComponent({
    message,
    preferences,
    showMorphemeTranslationDetails,
    wordBank,
    isExpanded,
    selectedMorphemes,
    setSelectedMorphemes,
}) {
    useEffect(() => {
        if (isExpanded) return;
        setSelectedMorphemes([]);
    }, [isExpanded, setSelectedMorphemes]);

    return (
        <span>
            {message.originalMessage && (
                <div className="original-message shared-message-content">
                    <span>{message.originalMessage}</span>
                </div>
            )}

            {showMorphemeTranslationDetails && message.morphemeTranslationDetails ? (
                <div className={`shared-message-content`}>
                    <MorphemesComponent
                        {...{
                            message,
                            preferences,
                            isExpanded,
                            setSelectedMorphemes,
                        }}
                    />
                </div>
            ) : (
                <div className="message-text shared-message-content">
                    {message.targetLanguageMessage}
                </div>
            )}
            {message.fullSentenceTranslation && (
                <div className="full-sentence-translation shared-message-content">
                    {message.fullSentenceTranslation}
                </div>
            )}
        </span>
    );
}

const MorphemeMenu = ({ selectedMorphemes, setExpandedMessageId, fetchMorphemes }) => {
    const handleMarkAsMastered = async () => {
        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL}/update-morphemes-as-mastered`,
                { originalMorphemes: selectedMorphemes },
                {
                    withCredentials: true,
                }
            );
            await fetchMorphemes(); // Ensure fetchMorphemes completes before proceeding
        } catch (error) {
            console.error("Error marking morphemes as mastered:", error);
        }
    };

    const handleRefreshMastery = async () => {
        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL}/update-morphemes-as-not-mastered`, // This is the endpoint you provided earlier
                { originalMorphemes: selectedMorphemes },
                {
                    withCredentials: true,
                }
            );
            fetchMorphemes();
        } catch (error) {
            console.error("Error refreshing morpheme mastery:", error);
        }
    };

    const handleCancel = () => {
        setExpandedMessageId(null);
        // Add logic here for canceling the action or closing the menu
    };
    return (
        <div className="morpheme-menu">
            <md-outlined-button onClick={handleMarkAsMastered}>
                Mark as Mastered
            </md-outlined-button>
            <md-outlined-button onClick={handleRefreshMastery}>
                Refresh Mastery
            </md-outlined-button>
            <md-outlined-button onClick={handleCancel}>Cancel</md-outlined-button>
        </div>
    );
};
const MorphemesComponent = ({ message, preferences, isExpanded, setSelectedMorphemes }) => {
    const isRtlLanguage = (language) => {
        const rtlLanguages = [
            "Arabic",
            "Hebrew",
            "Persian",
            "Farsi",
            "Urdu",
            "Yiddish",
            "Kurdish",
            "Pashto",
        ];
        return rtlLanguages.includes(language);
    };

    // Determine text direction based on language
    const isRtl = isRtlLanguage(preferences.language);

    // Handle click events on morphemes
    const handleMorphemeClick = (word) => {
        if (!isExpanded) return;
        setSelectedMorphemes((prevSelected) =>
            prevSelected.includes(word)
                ? prevSelected.filter((morpheme) => morpheme !== word)
                : [...prevSelected, word]
        );
    };

    // Determine if morphemeArray is available and has elements
    const hasMorphemes =
        Array.isArray(message.morphemeArray) && message.morphemeArray.length > 0;

    // Rendering Section: Create JSX elements from morpheme objects or a single placeholder
    const morphemeElements = hasMorphemes ? (
        message.morphemeArray.map(
            ({
                key,
                originalMorpheme,
                translatedMorpheme,
                transliteratedMorpheme,
                opacity,
                isSelected,
            }) => (
                <div
                    key={key}
                    className={`translation-pair ${isExpanded ? "message-expanded" : ""} ${
                        isSelected ? "selected-morpheme" : ""
                    }`}
                    onClick={() => handleMorphemeClick(originalMorpheme)}
                >
                    <span className="original-word">{originalMorpheme}</span>
                    {preferences.showTransliteration && (
                        <span className="transliteration-word">
                            {transliteratedMorpheme}
                        </span>
                    )}
                    <span className="translated-word" style={{ opacity }}>
                        {opacity === 0 ? "" : translatedMorpheme}
                    </span>
                </div>
            )
        )
    ) : (
        <div className="translation-pair placeholder">
            <span className="original-word">&nbsp;</span>
            {preferences.showTransliteration && (
                <span className="transliteration-word" style={{ opacity: 0 }}>
                    &nbsp;
                </span>
            )}
            <span className="translated-word" style={{ opacity: 0 }}>
                &nbsp;
            </span>
        </div>
    );

    // Render the container with all morpheme elements or the placeholder
    return (
        <div
            className={`translation-grid ${message.sender} ${
                isExpanded ? "message-expanded" : ""
            } ${isRtl ? "rtl" : "ltr"}`}
        >
            {morphemeElements}
        </div>
    );
};

export default ConversationArea;
