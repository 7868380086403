import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import "./RegisterAndLoginPages.css";

function RegisterPage() {
    return (
        <div className="App">
            <RegisterManager />
        </div>
    );
}

const RegisterManager = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState(localStorage.getItem("username") || "");
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
    const [feedbackMessage, setFeedbackMessage] = useState("");

    const usernameInputRef = useRef(null); // Reference for the username input field
    const passwordInputRef = useRef(null); // Reference for the password input field

    const retypePasswordInputRef = useRef(null); // Reference for the retype password input field
    const accessCodeInputRef = useRef(null); // Reference for the access code input field

    useEffect(() => {
        import(`../../themes/${theme}.css`)
            .then(() => {})
            .catch((err) => {
                console.error(`Failed to load ${theme} theme`, err);
            });
        document.body.className = theme;
    }, [theme]); // Re-run effect when theme changes

    async function registerUser(username, password, accessCode) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password, accessCode }),
            });

            const result = await response.json(); // Attempt to parse the response regardless of the status code
            console.log("register result: ", result);
            switch (response.status) {
                case 201:
                    setFeedbackMessage("");
                    navigate("/login"); // Navigate to the login page
                    break;
                case 400:
                    setFeedbackMessage(
                        result.message ||
                            "Invalid request. Please ensure all fields are correctly filled."
                    );
                    break;
                case 409:
                    setFeedbackMessage(
                        result.message ||
                            "An account with this username already exists. Please choose a different username."
                    );
                    break;
                default:
                    setFeedbackMessage(
                        result.message ||
                            "An error occurred during registration. Please try again."
                    );
                    break;
            }
        } catch (error) {
            // This catch block is for handling errors in the fetch operation itself, such as network issues.
            console.error("Network or server error:", error);
            alert(
                "Unable to connect to the server. Please check your internet connection and try again."
            );
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const username = usernameInputRef.current.value; // Get the current value of the username input
        const password = passwordInputRef.current.value; // Get the current value of the password input
        const retypePassword = retypePasswordInputRef.current.value; // Get the current value of the retype password input
        const accessCode = accessCodeInputRef.current.value; // Get the current value of the retype password input

        // Check if the password is at least 8 characters long
        if (password.length < 8) {
            setFeedbackMessage("Password must be at least 8 characters long.");
            return; // Stop the function if the condition is not met
        }

        // Check if both passwords match
        if (password !== retypePassword) {
            setFeedbackMessage("Passwords do not match.");
            return; // Stop the function if the condition is not met
        }
        registerUser(username, password, accessCode);

        localStorage.setItem("username", username); // Optionally save the username in localStorage
        setUsername(username);
    };

    const handlePasswordChange = (event) => {
        passwordInputRef.current.value = event.target.value;
        //console.log("password field changed: ", passwordInputRef.current.value);
    };

    return (
        <>
            <div className="login-container">
                <h1>Create Your Account</h1>
                <form
                    id="registerForm"
                    className="username-form"
                    method="dialog"
                    onSubmit={handleSubmit}
                >
                    <div className="input-container">
                        <md-outlined-text-field
                            label="Enter Your Username"
                            id="registerUsernameInput"
                            value={username}
                            ref={usernameInputRef}
                            placeholder="e.g., JohnDoe123"
                            required
                            editable
                            aria-label="Username"
                        />
                    </div>
                    <div className="input-container">
                        <md-outlined-text-field
                            label="Create a Password"
                            id="registerPasswordInput"
                            type="password"
                            onInput={handlePasswordChange}
                            ref={passwordInputRef}
                            placeholder="Password"
                            required
                            editable
                            aria-label="Create Password"
                        />
                    </div>
                    <div className="input-container">
                        <md-outlined-text-field
                            label="Retype Password"
                            id="retypeRegisterPasswordInput"
                            type="password"
                            ref={retypePasswordInputRef}
                            placeholder="Retype Password"
                            required
                            editable
                            aria-label="Retype Password"
                        />
                    </div>
                    <div className="input-container">
                        <md-outlined-text-field
                            label="Enter Your Access Code"
                            ref={accessCodeInputRef}
                            placeholder="A1A1A1"
                            required
                            editable
                            aria-label="Access Code"
                        />
                    </div>
                    <ul
                        style={{
                            textAlign: "left",
                            lineHeight: "1.5",
                            listStylePosition: "outside", // Ensures bullets are outside the text block
                        }}
                    ><li>Use an identifiable username <br/>(Unfamiliar users may be deleted)</li>
                        <li style={{ marginBottom: "10px" }}>
                            Do not reuse passwords
                            <a
                                href="https://xkcd.com/936/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: "block", // Makes the link start on a new line
                                    lineHeight: "0",
                                }}
                            >
                                (Try a passphrase!)
                            </a>
                        </li>
                        <li>Do not add sensitive information</li>
                    </ul>

                    <div className="form-actions">
                        <md-filled-button type="submit">Continue</md-filled-button>
                    </div>
                </form>
                <div className="error-message">{feedbackMessage}</div>
                <span>
                    Already have an account? <Link to="/login">Sign in</Link>
                </span>
                <Link to="/">Homepage</Link>
            </div>
        </>
    );
};
export default RegisterPage;
