import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import WebSocketService from "./WebSocketService";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const [connectionStatus, setConnectionStatus] = useState(
        WebSocketService.ws ? WebSocketService.ws.readyState : WebSocket.CONNECTING
    );

    // Handler for when the WebSocket opens
    const handleOpen = useCallback(() => {
        setConnectionStatus(WebSocket.OPEN);
        console.log("WebSocket opened");
    }, []);

    // Handler for when the WebSocket closes
    const handleClose = useCallback((event) => {
        const wasClean = event && event.wasClean;
        setConnectionStatus(wasClean ? WebSocket.CLOSED : WebSocket.CONNECTING);
        console.log("WebSocket closed", event);
    }, []);

    // Handler for when an error occurs
    const handleError = useCallback((error) => {
        console.error("WebSocket encountered error:", error);
    }, []);

    // Subscribe/unsubscribe from the WebSocketService events
    useEffect(() => {
        WebSocketService.on("open", handleOpen);
        WebSocketService.on("close", handleClose);
        WebSocketService.on("error", handleError);

        return () => {
            WebSocketService.off("open", handleOpen);
            WebSocketService.off("close", handleClose);
            WebSocketService.off("error", handleError);
        };
    }, [handleOpen, handleClose, handleError]);

    // Used by components to send a message through the service
    const sendMessage = useCallback((message) => {
        WebSocketService.sendMessage(message);
    }, []);

    // Used by components to force a reconnect
    const retryConnection = useCallback(() => {
        WebSocketService.closeWebSocket();
        WebSocketService.connectWebSocket();
    }, []);

    const value = {
        connectionStatus,
        sendMessage,
        retryConnection,
    };

    return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};
