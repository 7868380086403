import React from "react";

function ConversationPage() {
    return (
        <div>
            <h1>Conversation Page</h1>
            {/* Conversation UI goes here */}
        </div>
    );
}

export default ConversationPage;
