import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWebSocket } from "../../components/WebSocketContext"; // Import the useWebSocket hook

import "../../App.css";
import "./AdminPage.css";
import { DatabaseActions } from "./DatabaseActions";
import { RecordActions } from "./RecordActions";
import { ConfirmationDialog } from "./ConfirmationDialog";

const adminService = require("../../services/AdminService");

function AdminPage() {
  const navigate = useNavigate();
  //navigate('/login'); // Navigate to the login page
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  useEffect(() => {
    import(`../../themes/${theme}.css`)
      .then(() => {})
      .catch((err) => {
        console.error(`Failed to load ${theme} theme`, err);
      });
    document.body.className = theme;
  }, [theme]); // Re-run effect when theme changes

  return (
    <div className="App">
      <DatabaseManager />
    </div>
  );
}

const DatabaseManager = () => {
  const [databases, setDatabases] = useState([]); // Initializes the databases state
  const [selectedDatabase, setSelectedDatabase] = useState("");

  const [databaseInfo, setDatabaseInfo] = useState([]);
  const [deleteCandidate, setDeleteCandidate] = useState(null);
  const [resetPasswordCandidate, setResetPasswordCandidate] = useState(null);
  const [resetPasswordMessage, setResetPasswordMessage] = useState(
    "Are you sure you want to reset this user's password?"
  );

  const [selectedRecord, setSelectedRecord] = useState({
    field: null,
    id: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      //console.log("Fetching databases...");
      try {
        const response = await adminService.listDatabases();
        //console.log("Response data:", response.data);
        // Assuming the response structure is { "Databases": ["User", "AccessCode"] }
        if (response.data && Array.isArray(response.data.Databases)) {
          setDatabases(response.data.Databases);
        } else {
          console.error("Unexpected response structure:", response.data);
          setDatabases([]); // Fallback to an empty array if the structure is not as expected
        }
      } catch (error) {
        console.error("There was an error!", error);
        setDatabases([]); // Fallback to an empty array on error
      }
    };
    fetchData();
  }, []);

  const fetchDatabaseData = useCallback(async () => {
    if (selectedDatabase) {
      try {
        const response = await adminService.fetchDatabaseData(selectedDatabase);
        //console.log("response.data.data: ", response.data.data);
        if (response.data.data.length > 0) {
          const filteredData = response.data.data.map((entry) => {
            const { __v, passwordHash, ...rest } = entry;
            return rest;
          });
          setDatabaseInfo(filteredData);
        } else {
          setDatabaseInfo([]);
        }
      } catch (error) {
        console.error(
          "There was an error fetching data from",
          selectedDatabase,
          error
        );
      }
    }
  }, [selectedDatabase]);

  const handleDeleteConfirm = async () => {
    console.log("Confirm delete action triggered");
    await adminService.deleteDatabaseEntry(
      selectedDatabase,
      deleteCandidate.id
    );
    setDeleteCandidate(null);
    fetchDatabaseData();
  };
  const handleSetResetPassword = (selectedRecord) => {
    const id = selectedRecord.id; // Extract the actual id value from the selected record
    const itemToReset = databaseInfo.find((item) => item._id.value === id);

    if (itemToReset) {
      const keys = Object.keys(itemToReset);
      const firstKey = keys[0]; // Get the first key from the item

      // Ensure firstKey exists and is valid
      if (firstKey) {
        setResetPasswordCandidate({
          id: id, // Use the unwrapped id
          firstColumnName: firstKey.charAt(0).toUpperCase() + firstKey.slice(1),
          firstColumnValue: itemToReset[firstKey],
        });
      }
    }
  };
  const handleResetPassword = async () => {
    try {
      const response = await adminService.resetUserPassword(selectedRecord.id);
      if (response.data.success) {
        setResetPasswordMessage(
          `Password reset successfully. New password: ${response.data.newPassword}`
        );
      } else {
        setResetPasswordMessage("Failed to reset password.");
      }
    } catch (error) {
      //setMessage("Error resetting password.");
      console.error("Error:", error);
    }
    fetchDatabaseData();
  };

  const handleCancel = () => {
    setDeleteCandidate(null);
    setResetPasswordCandidate(null);
    setResetPasswordMessage(
      "Are you sure you want to reset this user's password?"
    );
  };

  return (
    <div className="admin-container">
      <aside className="side-panel open">
        <div className="side-panel-selection">
          <h3>Select Database</h3>
          <ul>
            {databases.map((db, index) => (
              <li
                key={index}
                className={db === selectedDatabase ? "selected" : ""}
                onClick={() => setSelectedDatabase(db)}
              >
                {db}
              </li>
            ))}
          </ul>
            <h3>Select Action</h3>
            <DatabaseActions
              selectedDatabase={selectedDatabase}
              fetchDatabaseData={fetchDatabaseData}
            />
          {selectedRecord.id && (
            <>
                <h3>Select Record Action</h3>
                <RecordActions
                  selectedDatabase={selectedDatabase}
                  fetchDatabaseData={fetchDatabaseData}
                  selectedRecord={selectedRecord}
                  handleResetPassword={handleResetPassword}
                  handleSetResetPassword={handleSetResetPassword}
                />
            </>
          )}
          {deleteCandidate && (
            <>
              <h3>Permanently Delete?</h3>
              <ConfirmationDialog
                message="Are you sure you want to delete this item?"
                details={{
                  label: deleteCandidate?.firstColumnName,
                  value: deleteCandidate?.firstColumnValue.value,
                }}
                onClose={handleCancel}
                onConfirm={handleDeleteConfirm}
                confirmButtonLabel="Delete"
                cancelButtonLabel="Cancel"
              />
            </>
          )}
          {resetPasswordCandidate && (
            <>
              <h3>Reset Password?</h3>
              <ConfirmationDialog
                message={resetPasswordMessage}
                details={{
                  label: resetPasswordCandidate?.firstColumnName,
                  value: resetPasswordCandidate?.firstColumnValue.value,
                }}
                onClose={handleCancel}
                onConfirm={handleResetPassword}
                confirmButtonLabel="Reset"
                cancelButtonLabel="Cancel"
              />
            </>
          )}
        </div>
      </aside>
      <main className="main-content">
        <DataGridContainer
          selectedDatabase={selectedDatabase}
          fetchDatabaseData={fetchDatabaseData}
          databaseInfo={databaseInfo}
          setDeleteCandidate={setDeleteCandidate}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      </main>
    </div>
  );
};
function DataGridContainer({
  selectedDatabase,
  fetchDatabaseData,
  setDeleteCandidate,
  databaseInfo,
  selectedRecord,
  setSelectedRecord,
}) {
  const dialogRefModify = useRef(null);
  const [modifyRowData, setModifyRowData] = useState();
  const [dataHistory, setDataHistory] = useState([]);

  useEffect(() => {
    fetchDatabaseData();
  }, [fetchDatabaseData]);

  // Utility function to get nested field value from an item
  const getNestedValue = (obj, key) => {
    return key.split(".").reduce((o, x) => (o === undefined ? o : o[x]), obj);
  };

  const handleItemClick = (item, field, level) => {
    setSelectedRecord({ field, id: item._id.value }); // Assuming each item has an `id` property
    const clickedCellValue = getNestedValue(item, field);

    if (
      clickedCellValue !== null &&
      typeof clickedCellValue === "object" &&
      !(clickedCellValue instanceof Date)
    ) {
      if ("value" in clickedCellValue) {
        const newCellValue = clickedCellValue.value;
        setDataHistory((prev) => [
          ...prev.slice(0, level),
          { title: field, data: newCellValue },
        ]);
      } else {
        setDataHistory((prev) => [
          ...prev.slice(0, level),
          { title: field, data: clickedCellValue },
        ]);
      }
    } else {
      setDataHistory((prev) => prev.slice(0, level));
    }
  };

  useEffect(() => {
    if (modifyRowData) {
      dialogRefModify.current.show();
    }
  }, [modifyRowData]); // This effect runs only when modifyRowData changes
  useEffect(() => {
    setModifyRowData();
    setDataHistory([]);
  }, [selectedDatabase]);

  const handleEdit = (id) => {
    const item = databaseInfo.find((item) => item._id === id);
    setModifyRowData(item); // Update the state
    if (modifyRowData) {
      dialogRefModify.current.show();
    }
  };

  const handleCopy = (id) => {
    // Find the data row by ID
    const rowData = databaseInfo.find((item) => item._id === id);
    if (rowData) {
      // Convert the row data to JSON string with indentation
      const jsonString = JSON.stringify(rowData, null, 2); // "2" here specifies the number of spaces for indentation

      // Copy the formatted JSON string to clipboard
      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          //console.log('Formatted data copied to clipboard successfully!');
          // Optionally, show a notification or call a function to inform the user of the successful copy
        })
        .catch((err) => {
          //console.error('Failed to copy data: ', err);
          // Optionally, handle errors or inform the user of the failure
        });
    } else {
      //console.log('Failed to find the data with id:', id);
      // Optionally, handle cases where no data is found
    }
  };

  const handleDelete = (idWrapper) => {
    const id = idWrapper.value; // Extract the actual id value from the wrapper object
    const itemToDelete = databaseInfo.find((item) => item._id.value === id);

    if (itemToDelete) {
      const keys = Object.keys(itemToDelete);
      const firstKey = keys[0]; // Get the first key from the item

      // Ensure firstKey exists and is valid
      if (firstKey) {
        setDeleteCandidate({
          id: id, // Use the unwrapped id
          firstColumnName: firstKey.charAt(0).toUpperCase() + firstKey.slice(1),
          firstColumnValue: itemToDelete[firstKey],
        });
      }
    }
  };

  useEffect(() => {
    setDeleteCandidate(null);
  }, [selectedDatabase]); // Dependency array with selectedDatabase

  return (
    <div className="data-grid-container">
      <DatabaseGrid
        gridTitle={selectedDatabase}
        data={databaseInfo}
        selectedRecord={selectedRecord}
        renderActionButtons={{
          handleEdit: handleEdit,
          handleCopy: handleCopy,
          handleDelete: handleDelete,
        }}
        handleItemClick={(item, field) => handleItemClick(item, field, 0)}
      />
      {dataHistory.map((entry, index) => (
        <DatabaseGrid
          key={index}
          gridTitle={entry.title}
          data={entry.data}
          selectedRecord={selectedRecord}
          handleItemClick={(item, field) =>
            handleItemClick(item, field, index + 1)
          }
        />
      ))}
      {modifyRowData && (
        <ModifyEntryForm
          modifyRowData={modifyRowData}
          dialogRefModify={dialogRefModify}
          selectedDatabase={selectedDatabase}
          fetchDatabaseData={fetchDatabaseData}
          databaseInfo={databaseInfo}
        />
      )}
    </div>
  );
}

function DatabaseGrid({
  gridTitle,
  data,
  renderActionButtons,
  handleItemClick,
  selectedRecord,
}) {
  if (typeof data !== "object" || data === null) {
    return;
  }
  
  // Ensure data is always an array
  const dataArray = Array.isArray(data) ? data : [data];
  //console.log(gridTitle, " dataArray", dataArray);
  //console.log(gridTitle, " dataArray type", typeof(dataArray));

  // Determine fields from the first item in the data array
  const fields = dataArray.length > 0 ? Object.keys(dataArray[0]) : [];

  // OPTIMIZE
  // Try looking at each cell in inspect element and be horrified
  // It does not display rows in an organized way. It is just a collection or cells.
  const gridStyle = {
    gridTemplateColumns: `repeat(${fields.length}, 1fr) ${
      renderActionButtons ? "auto" : ""
    }`,
  };

  const renderValue = (item, field) => {
    const fieldValue = item[field];
    // Check if the field value is an object
    if (fieldValue && typeof fieldValue === "object") {
      // If the object has both 'old' and 'new' properties, format them
      if ("old" in fieldValue && "new" in fieldValue) {
        const oldValue =
          typeof fieldValue.old === "object"
            ? JSON.stringify(fieldValue.old)
            : fieldValue.old;
        const newValue =
          typeof fieldValue.new === "object"
            ? JSON.stringify(fieldValue.new)
            : fieldValue.new;
        return `Old: ${oldValue}, New: ${newValue}`;
      }
      // If the object has a 'value' property, handle it based on its type
      else if ("value" in fieldValue) {
        return typeof fieldValue.value === "object" &&
          !(fieldValue.value instanceof Date)
          ? JSON.stringify(fieldValue.value)
          : fieldValue.value !== undefined && fieldValue.value !== null
          ? fieldValue.value.toString()
          : "N/A";
      }
      // Fallback to JSON stringify if the object is not empty but does not fit the expected structure
      else {
        return JSON.stringify(fieldValue);
      }
    } else {
      // Handle non-object field values
      return fieldValue !== undefined && fieldValue !== null
        ? fieldValue.toString()
        : "N/A";
    }
  };
  return (
    <>
      <h1>{gridTitle}</h1>
      <div className="data-grid" style={gridStyle}>
        {fields.map((field) => (
          <div className="data-grid-header" key={field}>
            {field.charAt(0).toUpperCase() + field.slice(1)}
          </div>
        ))}
        {renderActionButtons && gridTitle && data && (
          <div className="data-grid-header data-grid-actions-column">
            Actions
          </div>
        )}
        {dataArray.map((item, index) => (
          <React.Fragment
            key={item._id && item._id.value ? item._id.value : index}
          >
            {fields.map((field) => (
              <div
                className={`data-grid-body ${
                  selectedRecord.field === field &&
                  selectedRecord.id === item._id.value
                    ? "selected-cell"
                    : ""
                }`}
                key={`${
                  item._id && item._id.value ? item._id.value : index
                }-${field}`}
                onClick={() => handleItemClick(item, field)}
              >
                {renderValue(item, field)}
              </div>
            ))}
            {renderActionButtons && (
              <div className="data-grid-body data-grid-actions-column data-grid-action-buttons">
                <md-icon-button
                  onClick={() => renderActionButtons.handleEdit(item._id)}
                >
                  <md-icon filled>edit</md-icon>
                </md-icon-button>
                <md-icon-button
                  onClick={() => renderActionButtons.handleCopy(item._id)}
                >
                  <md-icon filled>content_copy</md-icon>
                </md-icon-button>
                <md-icon-button
                  onClick={() => renderActionButtons.handleDelete(item._id)}
                >
                  <md-icon filled>delete</md-icon>
                </md-icon-button>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

const ModifyEntryForm = ({
  databaseInfo,
  modifyRowData,
  dialogRefModify,
  selectedDatabase,
  fetchDatabaseData,
}) => {
  const formatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/_/g, " ")
      .replace(/^[a-z]/, (match) => match.toUpperCase());
  };

  const autoResizeTextarea = (fieldRef) => {
    // Constants for the textarea resizing
    const LINE_HEIGHT = 24;
    const MIN_ROWS = 1;
    const MAX_ROWS = 8;
    const inputField = fieldRef.current;
    if (!inputField) return;
    const shadowRoot = inputField.shadowRoot;
    if (!shadowRoot) return;
    const inputElement = shadowRoot.querySelector(".input");
    if (!inputElement) return;
    inputElement.style.overflow = "hidden";
    inputElement.style.height = "auto";
    let desiredHeight = inputElement.scrollHeight;
    let minHeight = LINE_HEIGHT * MIN_ROWS;
    let maxHeight = LINE_HEIGHT * MAX_ROWS;
    desiredHeight = Math.min(Math.max(desiredHeight, minHeight), maxHeight);
    inputElement.style.height = `${desiredHeight}px`;
    inputElement.style.overflow = "";
  };
  // Determine fields from the first item in the data array
  const fields = databaseInfo.length > 0 ? Object.keys(databaseInfo[0]) : [];
  // Creating refs for each field
  const inputRefs = useRef({});

  const initializeAndUpdateInputs = useCallback(() => {
    // Ensure fields is defined and not undefined
    if (!fields) {
      console.warn("Fields are undefined");
      return; // Exit the function early if fields are undefined
    }
    // Ensure refs exist for all fields defined and update their values
    fields.forEach((field) => {
      if (field === "_id") {
        return; // Skip processing for '_id' field
      }
      if (!inputRefs.current[field]) {
        inputRefs.current[field] = React.createRef();
      }
      if (inputRefs.current[field].current) {
        let data = modifyRowData[field];
        let value;

        // Check if data is an object and use value if available, handle null values explicitly
        if (typeof data === "object" && data !== null && "value" in data) {
          value = data.value === null ? "" : data.value; // Check if value is null
        } else {
          value = data === null ? "" : data; // Handle null values for data not structured as an object
        }
        // If value itself is an object, convert it to a string with JSON.stringify
        if (typeof value === "object" && value !== null) {
          value = JSON.stringify(value, null, 2); // 2 spaces for indentation
        }
        // Set the value to input field
        inputRefs.current[field].current.value = value;
        //console.log(`Setting value for field ${field}:`, value);
        // Apply autoResizeTextarea to each field after setting the value
        // Ensure DOM updates are complete before resizing
        requestAnimationFrame(() =>
          autoResizeTextarea(inputRefs.current[field])
        );
      }
    });
  }, [fields, inputRefs, modifyRowData]); // Ensuring all dependencies are correctly listed

  // Call this function on initial render and whenever modifyRowData changes
  useEffect(() => {
    initializeAndUpdateInputs();
  }, [initializeAndUpdateInputs, modifyRowData]); // Depend on modifyRowData to update input values
  initializeAndUpdateInputs();

  // Function to log changes in input values compared to initial modifyRowData
  const updateChangedInputValues = () => {
    let hasChanges = false;
    const updateData = {};
    // Function to check deep equality of objects
    const isEqual = (convertedValue, originalValue) => {
      // Check if either value had a conversion error and treat them as equal if so
      if (convertedValue.error) {
        return true;
      }
      if (
        typeof convertedValue.value === "object" &&
        typeof originalValue === "object"
      ) {
        return (
          JSON.stringify(convertedValue.value) === JSON.stringify(originalValue)
        );
      }
      return convertedValue.value === originalValue;
    };

    const convertToType = (value, targetType) => {
      let conversionError = false;

      if (targetType === "number") {
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
          console.error(
            `Conversion error: '${value}' cannot be converted to number.`
          );
          conversionError = true;
        }
        return { value: numberValue, error: conversionError };
      }

      if (targetType === "boolean") {
        if (value === "true") {
          return { value: true, error: false };
        } else if (value === "false") {
          return { value: false, error: false };
        } else {
          console.error(
            `Conversion error: '${value}' is not a recognizable boolean value.`
          );
          conversionError = true;
          // You may choose to return a default value or the raw input
          // Here we return false by default if it's not clearly true or false
          return { value: Boolean(value), error: conversionError };
        }
      }

      if (targetType === "object" || targetType === "array") {
        try {
          const parsedValue = JSON.parse(value);
          if (targetType === "array" && !Array.isArray(parsedValue)) {
            console.error(
              `Conversion error: JSON parsed but '${value}' is not an array as expected.`
            );
            conversionError = true;
          }
          return { value: parsedValue, error: conversionError };
        } catch (error) {
          console.error(
            `Conversion error: Could not parse '${value}' as ${targetType}. Error: ${error.message}`
          );
          return { value, error: true };
        }
      }

      return { value, error: conversionError }; // Assume string or any other type that doesn't need special handling
    };

    // Utility function to convert null to an empty string
    const normalizeValue = (value) => (value === null ? "" : value);

    Object.keys(inputRefs.current).forEach((key) => {
      if (inputRefs.current[key] && inputRefs.current[key].current) {
        const currentValue = inputRefs.current[key].current.value;
        const originalValue = normalizeValue(modifyRowData[key].value);
        const originalType = modifyRowData[key].type.toLowerCase();

        // Convert currentValue to the type of originalValue before comparing
        const convertedValue = convertToType(currentValue, originalType);

        if (!isEqual(convertedValue, originalValue)) {
          console.log(
            `${formatLabel(key)} changed from ${JSON.stringify(
              originalValue
            )} to ${JSON.stringify(convertedValue.value)}`
          );
          updateData[key] = convertedValue.value;
          hasChanges = true;
        }
      }
    });

    if (hasChanges) {
      const itemId = modifyRowData._id.value; // Assuming _id is the identifier in your data
      //console.log('Selected Database:', selectedDatabase, 'Item ID:', itemId, 'Update Data:', updateData);
      adminService
        .updateDatabaseEntry(selectedDatabase, itemId, updateData)
        .then((response) => {
          console.log("Update successful:", response.data);
          fetchDatabaseData();
        })
        .catch((error) => {
          // Check if the error response exists and has a data property
          if (error.response && error.response.data) {
            console.error(
              "Failed to update:",
              error.response.data.message || error.response.data
            );
          } else {
            // Fallback error message if the data property does not exist
            console.error("Failed to update:", error.message);
          }
        });
    } else {
      console.log("No changes to update.");
    }
  };

  // Organize fields into rows of 1
  const rows = [];
  const chunks = [...fields]; // Clone fields array to manipulate
  while (chunks.length) rows.push(chunks.splice(0, 1));

  return (
    <md-dialog className="modify-entry-dialog" ref={dialogRefModify}>
      <div slot="headline">Modify Entry?</div>
      <form id="form" slot="content" method="dialog" className="modify-content">
        {rows.map((row, index) => (
          <div key={index} className="modify-fields">
            {row.map((field) => {
              if (field !== "_id") {
                // Check to skip the '_id' field
                return (
                  <md-filled-text-field
                    key={field}
                    label={formatLabel(field)}
                    ref={inputRefs.current[field]}
                    type="textarea"
                    rows="1"
                    onInput={() => autoResizeTextarea(inputRefs.current[field])}
                  />
                );
              }
              return null; // Return null when field is '_id'
            })}
          </div>
        ))}
      </form>
      <div slot="actions">
        <md-text-button
          form="form"
          onClick={(event) => {
            event.preventDefault(); // Prevents the form from submitting
            initializeAndUpdateInputs(); // Calls your function to handle the initialization and update
          }}
        >
          Reset
        </md-text-button>
        <md-text-button
          form="form"
          onClick={updateChangedInputValues} // Apply updateChangedInputValues function on Ok button
        >
          Ok
        </md-text-button>
      </div>
    </md-dialog>
  );
};

export default AdminPage;
